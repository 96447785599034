body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
button:hover {
  cursor: pointer;
}
html {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
body {
  height: 100vh;
  overflow: hidden;
}
.App {
  height: 100vh;
}
.header {
  width: 100%;
  height: 60px;
  background-color: rgb(180, 120, 70);
  font-size: 2rem;
}
.header p {
  position: absolute;
  top: 5px;
  left: 30px;
}
.citySearchBox {
  display: flex;
  justify-content: center;
}
.header .citySearchBox {
  font-size: 0.8rem;
  position: absolute;
  left: 50%;
  top: 5px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 900;
}

.page {
  height: 100%;
}
.homePage {
  background: url(/static/media/kevin-young-7002-unsplash.309a70c3.jpg) no-repeat center
    fixed;
}

.homePageContent h1 {
  display: flex;
  justify-content: center;
  padding: 30px;
}
.homePageContent .citySearchBox {
  margin: 0 auto;
}
.citySearchBox input {
  padding: 10px;
  font-size: 1.5em;
  border: 1px solid grey;
}
.citySearchBox button {
  padding: 10px 20px;
  font-size: 1.5em;
  border: none;
  background-color: rgb(224, 226, 252);
}

@media (max-width: 930px) {
  .citySearchBox input {
    display: block;
    width: 100%;
  }
  .citySearchBox button {
    display: block;
    width: 100%;
  }
}

.autoCompleteBox {
  position: absolute;
  text-decoration: none;
  text-align: left;
  list-style: none;
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  z-index: 1000;
}

.searchHeader {
  background-color: gray;
}

.searchHeader input {
  font-size: 1.2em;
  padding: 5px 12px;
}

.searchHeader button {
  font-size: 1.2em;
  border: none;
  padding: 0;
  min-width: 200px;
}
.searchHeader button span {
  border-left: 1px solid black;
  padding: 3px 5px;
  float: right;
}
.searchHeader button:hover span {
  background-color: rgb(67, 91, 133);
}
.searchHeader button p {
  display: inline-block;
  padding: 3px 10px;
}

.searchHeader .searchInputContainer {
  display: inline-block;
  padding: 0px 10px;
}

.searchHeader .searchInputContainer .dropdownContainer {
  display: inline-block;
}

.suggestion {
  background-color: rgb(225, 238, 241);
  font-size: 1.2em;
  padding: 10px 20px;
  border-bottom: 1px solid rgb(136, 178, 189);
  overflow: hidden;
}

.suggestion:hover {
  background-color: rgb(179, 218, 228);
  cursor: pointer;
}

.dropdownContainer {
  position: relative;
  display: inline-block;
}

.clickCapture {
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(70, 70, 70, 0.3);
  z-index: 900;
}

.mapSearchResult {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-columns: 30% 70%;
  grid-template-rows: 1fr;
  border-top: 1px solid black;
}
.gridContainer {
  position: relative;
  max-height: 100%;
  display: block;
  overflow-y: scroll;
  padding-bottom: 120px;
}

.resultGrid {
  border-right: 1px solid black;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-rows: 200px;
  grid-auto-flow: row;
  grid-gap: 5px;
}

#mapDisplay {
  height: 100%;
  z-index: 10;
}

label:hover {
  cursor: pointer;
}

.resultThumbnail {
  position: relative;
  display: block;
}
.resultThumbnail:hover {
  cursor: pointer;
}

.resultThumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.resultThumbnail div {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 50%;
  max-width: 80%;
  background-color: rgb(125, 175, 233);
  border-radius: 0px 15px 0px 0px;
  -moz-border-radius: 0px 15px 0px 0px;
  -webkit-border-radius: 0px 15px 0px 0px;
  border-top: 1px solid rgb(36, 74, 117);
  border-right: 1px solid rgb(36, 74, 117);
}
.selectedThumb {
  box-shadow: 0px 0px 4px 4px rgb(0, 0, 0);
  opacity: 0.5;
}
.resultThumbnail span {
  padding-left: 5px;
}
.thumb_price {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
}
.thumb_bedbath {
  display: inline-block;
  font-size: 0.9rem;
  color: white;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
}

.resultsCount {
  display: inline;
  padding-left: 10px;
  color: white;
}

.detailsModul {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
}
.detailsContent {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 95%;
  width: 40%;
  background-color: white;
  z-index: 1000;
  overflow: hidden;
  box-shadow: 0px 0px 100px 10px rgb(0, 0, 0);
  display: grid;
  grid-template-rows: 40% 60%;
}

.detailsGallery {
  width: 100%;
  background-color: black;
}

.detailsGallery img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.infoSpecs {
  padding: 10px;
  font-size: 1.2em;
  border-bottom: 1px solid black;
  background-color: rgb(125, 175, 233);
  color: white;
  font-weight: 400;
}

.infoDescription {
  padding: 10px;
  font-size: 1.1em;
}

.garageSelector {
  display: inline-block;
  width: 20px;
  position: relative;
}
.garageSelector label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: #fcfff4;
  background: -webkit-gradient(linear, left top, left bottom, from(#fcfff4), color-stop(40%, #dfe5d7), to(#b3bead));
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 4px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}

.garageSelector label:after {
  content: "";
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 3px solid #333;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.garageSelector label:hover::after {
  opacity: 0.5;
}
.garageSelector input[type="checkbox"] {
  visibility: hidden;
}
.garageSelector input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

@media (max-width: 1370px) {
  .resultsCount {
    display: none;
  }
  .searchHeader input {
    font-size: 0.8em;
  }

  .searchHeader button {
    font-size: 0.8em;
    min-width: 150px;
  }
  .mapSearchResult {
    grid-template-columns: 40% 60%;
  }
}

